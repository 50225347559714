<template>
  <div>
    <div class="text-center">
      <a class="btn btn-outline-primary btn-block hand-pointer" v-on:click="showTransfer = !showTransfer">
        Transfer ownership
      </a>
    </div>

    <span v-if="showTransfer" class="text-left">

      <small class="form-text text-muted text-left pt-2">
        Enter the wallet address
      </small>
      <input type="text"
             class="form-control text-left"
             id="recipient"
             v-model="form.recipient"
             placeholder="0x123...."
             required/>

      <button class="btn btn-primary text-left mt-1"
              v-on:click="transferAsset"
              :disabled="!(tokenId && isValidAddress)">
        Send
      </button>

      <view-transaction-details :transaction="transactionHash">
      </view-transaction-details>
    </span>
  </div>
</template>

<script>
  import Web3 from 'web3';
  import * as actions from '../../store/actions';
  import ViewTransactionDetails from './ViewTransactionDetails';

  export default {
    name: 'transfer-token-box',
    components: {ViewTransactionDetails},
    data() {
      return {
        form: {
          recipient: null
        },
        showTransfer: false,
        transactionHash: null
      };
    },
    props: {
      tokenId: {
        type: [String, Number]
      }
    },
    computed: {
      isValidAddress() {
        if (!this.form.recipient || this.form.recipient.length === 0) {
          return false;
        }
        return Web3.utils.isAddress(this.form.recipient);
      }
    },
    methods: {
      transferAsset() {
        if (this.isValidAddress) {
          this.$store.dispatch(`${actions.TRANSFER_TOKEN}`, {
            tokenId: this.tokenId,
            recipient: this.form.recipient
          })
            .then((hash) => {
              this.transactionHash = hash;
            });
        }
      },

    },
  };
</script>

<style scoped lang="scss">
  @import '../../ko-colours.scss';
  @import '../../ko-card.scss';

  .btn {
    font-family: 'Roboto 400', sans-serif;
  }

  .btn-outline-primary {
    color: $primary !important;
  }

  .btn-outline-primary:hover {
    color: #FFF !important;
    background-color: $primary;
    border-color: $primary;
  }

</style>
