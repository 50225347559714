<template>

  <div>

    <div v-if="shouldDisplayHighRes">

      <!-- Initial download button -->
      <a class="btn btn-success text-white btn-block hand-pointer"
         v-if="!isHighResDownloadTriggered(edition.tokenId) && !isHighResDownloadSuccess(edition.tokenId) && !isHighResDownloadFailed(edition.tokenId)"
         @click="verifyPurchase">
        High-res download
      </a>

      <!-- Triggered -->
      <a class="btn btn-success btn-block text-white disabled"
         v-if="isHighResDownloadTriggered(edition.tokenId) && !isHighResDownloadFailed(edition.tokenId)"
         @click="noop">
        <font-awesome-icon :icon="['fas', 'cog']" spin></font-awesome-icon>
        In progress
      </a>

      <!-- Success -->
      <span v-if="isHighResDownloadSuccess(edition.tokenId)">
      <a class="btn btn-success btn-block text-white"
         :href="getHighResDownloadedLink(edition.tokenId)" target="_blank">
        <font-awesome-icon :icon="['fas', 'download']"></font-awesome-icon> Download high-res
      </a>
      <small class="text-muted">This download will expire in approximately 10 minutes and you can download the file a maximum of 3 times.</small>
    </span>

      <!-- Failure -->
      <span v-if="isHighResDownloadFailed(edition.tokenId)">
      <a class="btn btn-danger btn-block text-white disabled"
         @click="noop">
         <font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon> Failure
      </a>
      <small class="text-muted">
        Please contact us on
        <a href="https://t.me/notreal_io" target="_blank" class="community-icon" title="Telegram">telegram</a>
        or
        <a href="mailto:play@notreal.co" target="_blank" title="Hello">email</a> if you think this is incorrect.
        <button class="btn btn-sm btn-link text-muted" v-on:click="showMore = !showMore"
                v-if="!showMore">details</button>
      </small>
      <pre class="text-muted" v-show="showMore">{{highResDownload[edition.tokenId].message}} | {{hostname()}}</pre>
    </span>

    </div>

    <div v-else-if="isTokenOwner">
      <a class="btn btn-success btn-block text-white hand-pointer" @click="simpleHighResDownload">
        Download Image
      </a>
    </div>
  </div>
</template>

<script>
  import * as actions from '../../store/actions';
  import {mapGetters, mapState} from 'vuex';
  import * as _ from 'lodash';

  export default {
    name: 'highResDownload',
    components: {
    },
    props: ['edition', 'version'],
    data() {
      return {
        showMore: false
      };
    },
    computed: {
      ...mapState([
        'account',
      ]),
      ...mapState('highres', [
        'highResDownload',
      ]),
      ...mapGetters('highres', [
        'isHighResDownloadTriggered',
        'isHighResDownloadSuccess',
        'isHighResDownloadFailed',
        'getHighResDownloadedLink'
      ]),
      shouldDisplayHighRes() {
        return this.isTokenOwner && this.edition.highResAvailable;
      },
      isTokenOwner() {
        return (this.edition && this.account) && (_.toLower(this.edition.owner) === _.toLower(this.account));
      },
    },
    methods: {
      verifyPurchase() {

        this.$ga.event('high-res-download', 'high-res-download-triggered');

        this.$store.dispatch(`highres/${actions.HIGH_RES_DOWNLOAD}`, {
          edition: this.edition,
          contractVersion: this.version
        });
      },
      simpleHighResDownload() {
        this.$ga.event('high-res-download', 'high-res-download-simple');

        window.open(this.edition.lowResImg, "_blank");
      },
      hostname() {
        return _.get(window, 'location.hostname');
      },
      noop: () => {
      }
    },
  };
</script>

<style scoped lang="scss">
  @import '../../ko-colours.scss';
  @import '../../ko-card.scss';

  .btn {
    font-family: 'Roboto 400', sans-serif;
  }

</style>
