<template>
  <a
    :href="'https://opensea.co/assets/0xfbeef911dc5821886e1dda71586d90ed28174b7d/' + tokenId + '?ref=0x3f8c962eb167ad2f80c72b5f933511ccdf0719d4'"
    target="_blank" class="btn btn-outline-info btn-block">
    Trade on OpenSea
  </a>
</template>

<script>
  export default {
    name: 'trade-on-open-sea',
    components: {},
    props: {
      tokenId: {
        type: [String, Number]
      }
    },
  };
</script>
