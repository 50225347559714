<template>
  <div class="mt-4">

    <loading-section :page="PAGES.EDITION_TOKEN_OVERVIEW"></loading-section>

    <div v-if="!isLoading(PAGES.EDITION_TOKEN_OVERVIEW) && !edition"
         class="row justify-content-sm-center">
      <div class="col col-sm-6 text-center">
        <div class="alert alert-secondary" role="alert">
          Sorry, we cant seem to find that art work
        </div>
      </div>
    </div>

    <div class="row editions-wrap" v-if="!isLoading(PAGES.EDITION_TOKEN_OVERVIEW) && edition">

      <div class="col-sm-5 col-lg-5 offset-sm-1 offset-lg-2 mb-5">
        <div class="card">
          <edition-image class="card-img-top" :edition="edition" :id="edition.edition" :full="true"/>
        </div>
      </div>

      <div class="col-sm-5 col-lg-3 mb-5">

        <edition-card :edition="edition">
          <div class="mt-2">
            <high-res-download :edition="edition" :version="2" class="mt-3"></high-res-download>
            <trade-on-open-sea :token-id="edition.tokenId" class="mt-3"></trade-on-open-sea>
            <transfer-token-box :token-id="edition.tokenId" v-if="isTokenOwner()" class="mt-3"></transfer-token-box>
          </div>
        </edition-card>

        <token-history-event-list class="pt-3" :edition="edition"></token-history-event-list>

      </div>

    </div>

    <other-artist-editions class="pt-4" :edition="edition"></other-artist-editions>

  </div>
</template>

<script>
  import * as _ from 'lodash';
  import {mapGetters, mapState} from 'vuex';
  import LoadingSection from '../components/generic/LoadingSection';
  import {PAGES} from '../store/loadingPageState';
  import * as actions from '../store/actions';
  import EditionImage from '../components/generic/EditionImage';
  import EditionCard from '../components/cards/EditionCard';
  import EditionSoldOut from '../components/purhcase/EditionSoldOut';
  import EditionNotOnSaleYet from '../components/purhcase/EditionNotOnSaleYet';
  import PlaceEditionBid from '../components/auction/PlaceEditionBid';
  import ClickableAddress from '../components/generic/ClickableAddress';
  import HighResDownload from '../components/highres/HighResDownload';
  import TradeOnOpenSea from '../components/generic/TradeOnOpenSea';
  import TransferTokenBox from '../components/generic/TransferTokenBox';
  import OtherArtistEditions from '../components/purhcase/OtherArtistEditions';
  import TokenHistoryEventList from '../components/cards/TokenHistoryEventList';

  export default {
    name: 'editionTokenOverview',
    components: {
      TokenHistoryEventList,
      OtherArtistEditions,
      TransferTokenBox,
      TradeOnOpenSea,
      HighResDownload,
      ClickableAddress,
      PlaceEditionBid,
      EditionNotOnSaleYet,
      EditionSoldOut,
      EditionCard,
      EditionImage,
      LoadingSection,
    },
    metaInfo() {
      if (this.edition) {
        return {
          title: `${this.edition.name} | NotReal.ai`
        };
      }
    },
    data() {
      return {
        PAGES: PAGES,
        edition: null
      };
    },
    methods: {
      isTokenOwner() {
        return (this.edition && this.account) && (_.toLower(this.edition.owner) === _.toLower(this.account));
      }
    },
    computed: {
      ...mapState([
        'account',
        'editionLookupService'
      ]),
      ...mapGetters('loading', [
        'isLoading'
      ]),
    },
    created() {
      this.$store.dispatch(`loading/${actions.LOADING_STARTED}`, PAGES.EDITION_TOKEN_OVERVIEW);

      const loadData = () => {
        this.editionLookupService.loadToken(this.$route.params.tokenId)
          .then((edition) => {
            this.edition = edition;
          })
          .finally(() => {
            this.$store.dispatch(`loading/${actions.LOADING_FINISHED}`, PAGES.EDITION_TOKEN_OVERVIEW);
          });
      };

      this.$store.watch(
        () => this.editionLookupService.currentNetworkId,
        () => loadData()
      );

      if (this.editionLookupService.currentNetworkId) {
        loadData();
      }
    },
  };
</script>

<style scoped>

</style>
