<template>
  <div v-if="tokenHistory.length > 0">

    <hr/>

    <h6>History</h6>

    <div v-for="event in limitBy(orderBy(tokenHistory, 'blockNumber', -1), 10)" :key="event.id" class="mt-3">

      <div>
        <code class="small">{{event.event | humanize}}</code>
        <small v-if="event.blockTimestamp">
          {{ event.blockTimestamp | moment('from')}}
        </small>

        <view-transaction-details v-if="event.event ==='Transfer' || event.event ==='EditionCreated' "
                                  :transaction="event.transactionHash"
                                  class="small float-right">
        </view-transaction-details>

        <span class="float-right small" v-if="event._args._amount">
          <price-in-eth :value="event._args._amount | toEth"></price-in-eth>
          <u-s-d-price-converter
            :price-in-wei="event._args._amount"
            :usd-exchange-rate="event.exchangeRate.usd">
          </u-s-d-price-converter>
        </span>
        <span class="float-right small" v-if="event._args._priceInWei && event._args._priceInWei > 0">
          <price-in-eth :value="event._args._priceInWei | toEth"></price-in-eth>
          <u-s-d-price-converter
            :price-in-wei="event._args._priceInWei"
            :usd-exchange-rate="event.exchangeRate.usd">
          </u-s-d-price-converter>
        </span>
      </div>

      <div v-if="event.event ==='Transfer'">
        <div v-if="event._args._from === '0x0000000000000000000000000000000000000000'" class="text-muted small">
          Token Birth
        </div>
        <div v-else>
          <span class="text-muted small">From:</span>
          <clickable-address :eth-address="event._args._from"></clickable-address>
          <span class="float-right">
            <span class="text-muted small">To:</span>
            <clickable-address :eth-address="event._args._to"></clickable-address>
          </span>
        </div>
      </div>

      <div v-if="event._args._bidder" class="mb-2 text-muted">
        <clickable-address :eth-address="event._args._bidder" class=""></clickable-address>
        <span v-if="event._args._tokenId" class="">
          <router-link :to="{ name: 'edition-token', params: { tokenId: event._args._tokenId.toString() }}"
                       class="badge badge-primary float-right">
            {{ event._args._tokenId.toString() }}
          </router-link>
        </span>
      </div>

      <div v-if="event._args._buyer" class="mb-2 text-muted">
        <clickable-address :eth-address="event._args._buyer" class=""></clickable-address>
        <span v-if="event._args._tokenId" class="">
          <router-link :to="{ name: 'edition-token', params: { tokenId: event._args._tokenId.toString() }}"
                       class="badge badge-primary float-right">
            {{ event._args._tokenId.toString() }}
          </router-link>
        </span>
      </div>

    </div>
  </div>
</template>

<script>
  import ClickableAddress from '../generic/ClickableAddress';
  import PriceInEth from '../generic/PriceInEth';
  import USDPrice from '../generic/USDPrice';
  import ClickableTransaction from '../generic/ClickableTransaction';
  import ViewTransactionDetails from '../generic/ViewTransactionDetails';
  import USDPriceConverter from '../generic/USDPriceConverter';
  import {mapGetters, mapState} from 'vuex';

  export default {
    name: 'TokenHistoryEventList',
    components: {
      ViewTransactionDetails,
      ClickableTransaction,
      PriceInEth,
      ClickableAddress,
      USDPriceConverter,
      USDPrice
    },
    props: {
      edition: {
        type: Object
      }
    },
    data() {
      return {
        tokenHistory: []
      };
    },
    computed: {
      ...mapState([
        'eventService',
      ]),
    },
    methods: {},
    created() {

      const loadData = () => {
        this.eventService
          .loadTokenHistoryEvents(this.edition)
          .then((tokenHistory) => {
            this.tokenHistory = tokenHistory;

            this.eventService
              .findBirthTransaction(this.edition)
              .then((data) => {
                this.tokenHistory.push(data);
              });
          });
      };

      this.$store.watch(
        () => this.eventService.currentNetworkId,
        () => loadData()
      );

      if (this.eventService.currentNetworkId) {
        loadData();
      }
    },
  };
</script>

<style scoped lang="scss">
</style>
